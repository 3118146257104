body {
    min-width: 1000px;
}

.logo {
    height: 32px;
    background: rgba(255,255,255,.2);
    margin: 16px;
    text-align: center;
    vertical-align: middle;
    color: #ffffff;
    line-height: 30px;
}

.logo .ant-select {
    width: 100%;
}
.logo .ant-select-selection-selected-value{
    text-align: center;
    width: 100%;
}
.logo .ant-select-arrow {
    opacity: 0.5;
    color: white;
}
.logo .ant-select-selection{
    color: white;
    border: none;
    background: transparent;
}

.left_menu a {
    color:white;
}

#app .ant-breadcrumb {
    margin: 16px 0;
    padding-left: 16px;
}

#app .content {
    padding: 24px;
    background: #fff;
    min-height: 500px;
}

.ant-table-row td {
    cursor: pointer;
}

.auth-form h2 {
    text-align: center;
}
.auth-form {
    margin:100px auto 0;
    width: 400px;
    height: 400px;
}
.auth-form .login-btn {
}
.auth-form .error {
    color: #FF0000;
}
.auth-form .row {
    /*margin: 0 auto;*/
    text-align: center;
}

.auth-form div + div {
    margin-top: 5px;
}

.good-search-input {
    width: 500px;
    margin-left: 20px;
    display: inline-block;
}
